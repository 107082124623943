﻿@import "../../Styles/Base/Helpers";

$chat-settings: (
    Bubbles: (
        Left: (
            Background: palette(Dark, Lighter-80),
            Color: palette(Dark, Lighter-30),
        ),
        Right: (
            Background: palette(Primary, Base),
            Color: palette(Neutral, White),
        )
    ),
    Label: (
        Color: palette(Dark, Lighter-50),
    ),
    TextBar: (
        Background: palette(Dark, Lighter-85)
    )
);

.c-Chat {
    position: relative;

    --bd-Chat-TextBar-Background: #{map-deep-get($chat-settings, TextBar, Background)};

    $chat: &;

    &__Body {
        height: 425px;
        margin-right: .5rem;
        overflow-y: auto;
        overflow-x: hidden;
        padding-bottom: 1.5rem;
        padding-left: 1.5rem;
        padding-right: 1rem;
        padding-top: 1.5rem;
        @include scrollbar(4px, #{palette(Primary, Base)}, $background-color: transparent, $border-radius: 5px);
    }

    &__Conversation {
        list-style: none;
    }

    &__LoadMore {
        text-align: center;
    }

    &__Message {
        display: flex;
        flex-direction: column;
        padding-bottom: 1rem;
        padding-top: 1rem;

        @each $bubble, $properties in map-get($chat-settings, Bubbles) {
            &--#{$bubble} {
                --bd-Chat-Bubble-Background: #{map-get($properties, Background)};
                --bd-Chat-Bubble-Color: #{map-get($properties, Color)};
            }
        }

        &--Left {
            align-items: flex-start;

            #{$chat}__Avatar {
                order: 0;
                margin-right: .25rem;
            }

            #{$chat}__Bubble {
                border-bottom-left-radius: 0px;
                order: 1;
            }

            #{$chat}__Label {
                margin-left: calc(25px + .25rem);
            }
        }

        &--Right {
            align-items: flex-end;

            #{$chat}__Avatar {
                order: 1;
                margin-left: .25rem;
            }

            #{$chat}__Bubble {
                border-bottom-right-radius: 0px;
                order: 0;
            }

            #{$chat}__Label {
                margin-right: calc(25px + .25rem);
            }
        }
    }

    &__Speech {
        align-items: flex-end;
        display: flex;
    }

    &__Bubble {
        background: var(--bd-Chat-Bubble-Background);
        border-radius: .5rem;
        color: var(--bd-Chat-Bubble-Color);
        font-size: .875rem;
        padding: .75rem 1rem;
        white-space: pre-line;
    }

    &__Label {
        color: var(--bd-Chat-Label-Color);
        font-size: .75rem;
        margin-top: .25rem;

        --bd-Chat-Label-Color: #{map-deep-get($chat-settings, Label, Color)};
    }

    &__TextBar {
        background-color: var(--bd-Chat-TextBar-Background);
        display: flex;
        flex-wrap: wrap;
        flex-shrink: 0;
        align-items: center;
        justify-content: space-between; 
        padding-bottom: 1rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-top: 1rem;
        position: relative;
    }

    &__TextField {
        box-shadow: none;
        flex-basis: 85%;

        .o-TextField__Input {
            min-height: 2.75rem;
            max-height: 5.5rem;
            overflow: hidden;
        }
    }
}